.InputPassword {
  width: 100%;
  position: relative;

  .input {
    @include font(18, 18, 500, rgb(255 255 255 / 15%));
    width: 100%;
    min-height: 34px;
    max-height: 45px;
    margin: 0;
    padding: 8px 15px;
    background: rgb(255 255 255 / 15%);
    border: 0;
    border-radius: 5px;
  }

  .visible {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    bottom: 0;
    cursor: pointer;

    &::after {
      content: '';
      width: 110%;
      height: 2px;
      display: block;
      position: absolute;
      transform: rotate(45deg);
      transition: 0.2s opacity ease;
      border-radius: 10px;
      opacity: 0;
      background-color: #fff;
    }

    &[data-active='false'] {
      &::after {
        opacity: 1;
      }
    }

    img {
      height: 20px;
    }
  }
}
