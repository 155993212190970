.auth-form_field {
  .required-field {
    float: right;
    margin-top: 0;
  }
  .InputPassword {
    .input {
      padding: 0;
      .ant-input-suffix {
        position: absolute;
        right: 10px;
        top: 30%;
      }
      .ant-input-password-icon {
        color: white;
        box-shadow: none;
        svg {
          background-color: transparent;
        }
      }
    }
    .ant-input {
      padding: 8px 15px;
      color: white;
      border-radius: 5px;
    }
    .ant-input-affix-wrapper-focused {
      .ant-input-suffix {
        background-color: transparent;
      }
    }
    .ant-select {
      width: 100%;
      .ant-select-selector {
        height: 45px;
        border: unset;
        background: rgb(38 38 38);
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          display: flex;
          color: white;
          align-items: center;
        }
      }
      .ant-select-arrow {
        color: white;
      }
    }
  }
  .react-international-phone-dial-code-preview {
    background: rgba(255, 255, 255, 0.15);
    border: none;
    color: white;
    margin-right: 0;
  }
}
