.info-page {
  @include pxToEm(margin, 40 0);

  .text {
    @include font(16, 16, 400, #fff);
  }
}

.pages-layout {
  @include pxToEm(margin, 30 0);

  @media (max-width: 767px) {
    @include pxToEm(margin-top, 10);
    @include pxToEm(margin-bottom, 10);
  }
}
.ant-notification {
  .ant-notification-notice {
    padding: 13px 18px;
    &.ant-notification-notice-error {
      background: #bf0017;
      .anticon-close-circle {
        display: none;
      }
    }
    &.ant-notification-notice-success {
      background: #02bd71;
      .anticon-check-circle {
        display: none;
      }
    }
    &.ant-notification-notice-warning {
      background: #faad14;
      .anticon-exclamation-circle {
        display: none;
      }
    }
    &.ant-notification-notice-info {
      background: #ce6f00;
      .anticon-info-circle {
        display: none;
      }
    }
    .ant-notification-notice-with-icon {
      .ant-notification-notice-message {
        color: #fff;
        margin-inline-start: 0;
        margin-bottom: 3px;
      }
    }
    .ant-notification-notice-close {
      color: #fff;
    }
  }
}
