.auth-form {
  --width-form: 360px;

  width: 100%;
  margin: auto;
  max-width: var(--width-form);

  @include pxToEm(margin-top, 50);
  @include pxToEm(margin-bottom, 50);

  position: relative;

  &_title {
    // @include pxToEm(padding-top, 40);
    @include pxToEm(margin-bottom, 20);
    @include font(22, 22, 700, var(--auth-forms-title-color));
    text-align: center;
  }

  &_social_buttons {
    @include flexbox($justifyContent: center, $alignItems: center, $wrap: wrap);
    @include pxToEm(margin-top, 8);

    > div {
      cursor: pointer;
    }

    button {
      margin: 5px auto !important;
    }
  }

  &_social_tab {
    @include flexbox($justifyContent: center, $alignItems: center, $wrap: wrap);
    @include pxToEm(margin-bottom, 12);
    width: 100%;
    margin-top: -3px;

    > div {
      cursor: pointer;
    }

    button {
      margin: 5px auto !important;
    }
  }

  &_social_title {
    text-align: center;
    @include pxToEm(margin-top, 10);
    @include font(16, 14, 400, var(--auth-forms__socialtitle-color));
  }

  &_wrapper {
    @include flexbox($justifyContent: center, $alignItems: center, $flow: column);
    @include pxToEm(padding, 25);

    position: relative;

    border: var(--auth-forms-border);

    z-index: 1;
    transition: 0.12s ease-out;
    background-color: var(--auth-forms-bg);
    backdrop-filter: blur(4px);
    border-radius: 16px;

    box-shadow: var(--auth-forms-shadow);

    form {
      width: 100%;
    }
  }

  &_field {
    width: 100%;

    @include pxToEm(margin-bottom, 20);

    &.mt30 {
      @include pxToEm(margin-top, 30);
    }

    &:last-child {
      margin-bottom: 0;
    }

    input {
      font-family: Gordita, sans-serif !important;
      width: 100%;
      background: var(--auth-forms__input-bg) !important;
      border: 0;
      border-radius: var(--auth-forms__input-border-radius);
      color: var(--auth-forms__input-color);
      margin: 0;
      padding: 8px 15px;
      height: var(--auth-forms__input-height);
      font-weight: 400 !important;

      &::placeholder {
        @include font(16, 16, 400);
        color: var(--auth-forms__input-placeholder-color) !important;
      }
    }

    .react-international-phone-input-container {
      .react-international-phone-dial-code-preview,
      .react-international-phone-country-selector {
        background: var(--auth-forms__input-bg) !important;
      }
    }

    .ant-input-password {
      background: var(--auth-forms__input-bg) !important;
      border: 0;
      height: var(--auth-forms__input-height);

      input {
        height: 100%;
      }
    }
  }

  .or {
    @include flexbox($justifyContent: center, $alignItems: center, $flow: row nowrap);
    @include font(12, 12, 500, #fff);
  }

  &_buttons {
    @include pxToEm(margin-top, 30);
    width: 100%;

    &.reset-buttons {
      margin-top: 0;

      .submit-button {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    .button {
      @include font(14, 14, 400, #fff);
      border-radius: 5px;

      cursor: pointer;
      width: 100%;
      height: 100%;

      text-align: center;
      transition: 0.3s color ease-in-out;
    }
  }

  .submit-button {
    @include pxToEm(padding, 12);

    background: var(--auth-forms__button-success-bg);
    color: var(--auth-forms__button-success-color);
    text-transform: uppercase;

    &-disabled {
      filter: brightness(0.8);
      background: #ffffff26;
      pointer-events: none;
    }
  }

  .restore-button {
    @include pxToEm(margin, 5 0 0);

    background-color: var(--auth-forms__button-forgot-bg);
    color: var(--auth-forms__button-forgot-color);
    text-decoration: underline;
  }

  .signin-button {
    @include pxToEm(margin-top, 30);
    @include pxToEm(padding, 12);

    background: var(--auth-forms__button-reg-bg);
    color: var(--auth-forms__button-reg-color);
    text-transform: uppercase;

    a {
      text-decoration: none;
    }
  }

  .error {
    @include font(14, 14, 500, rgb(255 0 0));
    @include pxToEm(margin, 5 0 0 0);
    text-transform: capitalize;
    text-align: left;

    &--terms {
      text-transform: none;
    }
  }

  .handle-back-in-form {
    @include flexbox($justifyContent: center, $alignItems: center);
    @include pxToEm(margin, 20 0);
  }


  .telegram-auth {
    position: relative;
    width: 48px;
    height: 48px;
    overflow: hidden;

    &-iframe {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      opacity: 0;
      transform: translate(-50%, -50%) scale(2.4);

      iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .whatsapp-auth {
    cursor: pointer;
  }

  .whatsapp-auth-form_wrapper .resend-warning-message {
    @include pxToEm(margin, 10 0 30);
    color: var(--auth-forms__input-placeholder-color);
  }

  &.loader-form {
    min-height: 50vh;
  }

  &-tabs {
    & .tab {
      &__nav {
        gap: 10px;
        // margin-bottom: 15px;
        padding: 0;
        padding-bottom: 15px;


        .name {
          padding: 8px 12px;
          @include font(16, 14, 500, #fff);
          min-height: 35px;

          width: 100%;
          justify-content: center;

          // box-shadow: 0 4px 70px 0 rgba(163, 27, 246, 0.4);
          border-radius: 5px;
          background: var(--auth-forms__tab-second-bg);
          border: var(--auth-forms__tab-second-border);
          text-transform: capitalize;
          transition: 0.4s ease background;

          &.active {
    
            border: var(--auth-forms__tab-active-border);
            background: var(--auth-forms__tab-active-bg);

          }
        } 
      }

      &__content {
        .loader-form {
          min-height: 170px;
        }
      }
    }

    &.hide-nav {
      & .tab {
        &__nav {
          display: none;
        }

        &__content {
          .auth-form_wrapper {
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
          }
        }
      }
    }
  }

  &_content {
    position: relative;
    margin: auto;

    &_banner {
      max-width: calc(var(--width-form) * 2);
      @include flexbox($alignItems: stretch, $flow: row nowrap);
    }
  }

  &_banner {
    max-width: calc(var(--width-form) * 2);

    &_image {
      width: 100%;
      height: initial;
      object-fit: cover;
      object-position: center;

      width: 50%;
      min-height: 300px;
      // max-height: 500px;

      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }

    .tab {
      @include flexbox($flow: column nowrap);
      margin-top: 0;
      margin-bottom: 0;
      height: auto;
      width: 100%;
      // width: 50%;

      &__nav {
        width: 100%;
        max-width: 100%;

        &:first-child {
          border-top-left-radius: 0;
        }
      }

      &__content,
      .auth-form_wrapper {
        height: 100%;
      }

      .auth-form_wrapper {
        height: 100%;

        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border: none;
      }
    }
  }

  .hidden {
    display: none;
  }
}
