* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.container {
  width: 100%;
  max-width: 2560px;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;

  @media (max-width: 3200px) {
    max-width: 2200px;
  }

  @media (max-width: 2400px) {
    max-width: 1640px;
  }

  @media (max-width: 1920px) {
    max-width: 1680px;
  }

  @media (max-width: 1920px) {
    max-width: 1440px;
  }

  @media (max-width: 1600px) {
    max-width: 1240px;
  }

  @media (max-width: 1440px) {
    max-width: 1120px;
  }

  @media (max-width: 1240px) {
    max-width: 960px;
  }

  @media (max-width: 1072px) {
    max-width: 880px;
  }

  @media (max-width: 992px) {
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
}
