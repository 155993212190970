.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: var(--preloader-bg);
  z-index: 999;
  inset: 0;

  img {
    width: 64px;
    height: 64px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
