.age-verification-modal {
  @include flexbox($justifyContent: center, $alignItems: center);

  position: fixed;
  inset: 0;
  margin: auto;
  width: 100%;
  height: 100vh;

  background: url('../../../public/uploads/en_desktop_roulette_background.jpg') no-repeat center / cover;

  &_content {
    @include flexbox($justifyContent: center, $alignItems: center, $flow: column);
    @include pxToEm(padding, 20);

    background: var(--auth-forms-bg);
    border: var(--auth-forms-border);
    box-shadow: var(--auth-forms-shadow);
    border-radius: 16px;
    width: 100%;
    max-width: max(30%, min(500px, 80%));
  }
  h2 {
    @include font(32, 24, 500, #fff);
    @include pxToEm(margin, 0 0 10);
  }

  p {
    @include font(16, 14, 400, #fff);
    @include pxToEm(margin, 10 0);
  }

  .error {
    @include font(14, 14, 500, #ffa500);
    @include pxToEm(margin, 5 0);
  }

  .buttons-container {
    @include flexbox($gap: 5px);
    @include pxToEm(margin-top, 10);

    button {
      @include pxToEm(padding, 10 20);
      @include font(16, 14, 400, #fff);

      border: none;
      border-radius: 10px;

      transition: filter 0.2s ease;

      cursor: pointer;

      &:hover {
        filter: brightness(0.9);
      }
    }

    .confirm {
      background: #484ff5;
    }

    .cancel {
      background: #d63b3c;
    }
  }
}
