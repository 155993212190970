.tab {
  @include pxToEm(margin, 20 0);
  width: 100%;

  .border-bottom {
    border-bottom: var(--tabs__nav-link-border);
  }

  &__nav {
    @include flexbox($justifyContent: flex-start, $alignItems: stretch, $flow: row nowrap, $gap: 20px);
    width: 100%;

    overflow-x: scroll;
    overflow-y: hidden;

    scrollbar-width: none; // For Firefox

    @media (max-width: 767px) {
      @include pxToEm(padding-bottom, 10);
    }

    .name {
      @include font(20, 16, 400, var(--tabs__nav-link-color));
      @include flexbox($justifyContent: flex-start, $alignItems: center);

      cursor: pointer;
      letter-spacing: 0.01em;
      text-transform: uppercase;

      width: auto;
      min-width: max-content;
    }
  }

  &__swiper {
    width: 100%;
  }

  & .general {
    @media (max-width: 992px) {
      padding-left: 0;
      padding-right: 0;
    }

    .name.active {
      color: var(--tabs__nav-link-color-active);
    }
  }

  .nav-is-slider {
    @include flexbox($flow: row nowrap, $gap: 10px);
    // @include pxToEm(margin-bottom, 30);
    @include pxToEm(margin, 30 0 30 0);
    @include pxToEm(padding-bottom, 19);

    .tab__nav {
      overflow: clip;
      border-bottom: 0;
      padding-bottom: 0;
      margin: 0;
    }
  }

  .swiper-nav {
    @include flexbox($alignItems: center);

    cursor: pointer;
  }
}
