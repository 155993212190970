@use 'sass:math';

$maxWidth: 1280;
$browser-context: 14;

$space: 5px;
$spaceOut: 5px;

// Colors:
$white: #fff;
$black: #000;
$green: #35d675;
$yellow: #ffd22e;
$light-grey: #d9d9d9;

// Breakpoints
$xs: 425px;
$mobile: 992px;
$small: 1280px;
$medium: 1920px;
$large: 2400px;

@mixin size($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 320;

  font-size: calc(#{$mobSize}px + #{$addSize} * ((100vw - 320px) / #{$maxWidth}));
  line-height: normal;
}

@mixin font($size, $sizeMobile: null, $weight: 400, $color: null) {
  @include size($size, $sizeMobile);

  font-weight: $weight;

  @if $color != null {
    color: $color;
  }
}

@mixin input($bg, $border, $placeholder, $paddings) {
  width: 100%;
  min-height: 34px;
  max-height: 42px;
  margin: 0;

  border: 0;
  padding: 8px 15px;
  border-radius: var(--input-border-radius);

  font-family: Gordita, sans-serif;
  @if $paddings {
    padding: $paddings;
  }
  @if $bg {
    background: $bg;
  } @else {
    background: rgb(255 255 255 / 15%);
  }

  @if $border {
    border: $border;
  } @else {
    border: 1px solid #fff;
  }

  @if $placeholder {
    ::placeholder,
    &::-webkit-input-placeholder,
    ::-webkit-input-placeholder {
      color: $placeholder;
    }
  } @else {
    ::placeholder,
    &::-webkit-input-placeholder,
    ::-webkit-input-placeholder {
      color: #fff;
    }
  }

  @media (width >= 1920px) {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}
// @include input($bg: transparent, $border: 1px solid red, $placeholder: blue);

@mixin button($bg, $border: none, $isEnabled, $isDisabled) {
  border: $border;
  padding: 12px;
  background: $bg;
  border-radius: 10px;

  cursor: pointer;

  font-family: Gordita, sans-serif;

  &::after {
    content: none;
  }

  &[data-disabled='false'] {
    @if $isEnabled {
      background-color: $isEnabled;
    } @else {
      background-color: #3fbd53;
    }
  }

  &[data-disabled='true'] {
    @if $isDisabled {
      background-color: $isDisabled;
    } @else {
      background-color: rgb(255 255 255 / 20%);
    }
  }
}
// @include button($bg: red, $isEnabled: green, $isDisabled: blue);

@mixin nulled {
  margin: 0;

  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: 0;
}

@mixin flexbox($display: flex, $flow: null, $justifyContent: null, $alignItems: null, $gap: null, $wrap: null) {
  display: $display;
  flex-wrap: $wrap;
  flex-flow: $flow;
  align-items: $alignItems;
  justify-content: $justifyContent;
  gap: $gap;
}

// @include flexbox($placeContent: center, $placeItems: center, $wrap: wrap);

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

// * Vendors

@mixin vendor-prefixes($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

// .box {
//   @include vendor-prefixes(transition, all 0.3s ease);
// }

@mixin pxToEm($property, $value) {
  $values: ();

  @each $val in $value {
    $emValue: math.div($val, 16) + em;
    // eslint-disable-next-line
    $values: join($values, $emValue, space);
  }

  #{$property}: $values;
}

// @include pxToEm(width, 500);
