.auth-form-terms {
  @include font(15, 14, 400, var(--auth-forms__input-color));
  @include flexbox($alignItems: flex-start, $flow: row wrap);
  margin-top: 15px;

  width: 100%;

  .checkbox {
    @include pxToEm(margin-right, 8);
    width: 16px;
    height: 16px;
  }

  > span {
    @include flexbox($alignItems: center, $flow: row wrap);
    
    width: calc(100% - 40px);
    color: white;
  }

  svg {
    @include pxToEm(margin-right, 4);

    width: 20px;
    height: 20px;
  }

  a {
    @include pxToEm(margin-left, 4);

    text-transform: lowercase;
    text-decoration: underline;
    color: white;
  }
}
